@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700,800');

html {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, select, textarea, button {
  font: inherit;
}
